import React from "react"
import {  Breadcrumb } from "antd"
import { Link } from "react-router-dom"
//获取面包屑Item
export const getBreadItem = (list) => {
    const arr = [];
    function getItem(allList){
        allList.forEach((item,index) => {
            if(item.children && item.children.length>0){
                arr.push(
                    <Breadcrumb.Item key={index}>
                        <Link  to={item.redirect}>
                            {item.title}
                        </Link>
                    </Breadcrumb.Item>
                )
                getItem(item.children)

            }else{
                    arr.push(
                        <Breadcrumb.Item key={index}>

                            <Link  to={item.path}>
                                {item.title}
                            </Link>
                        </Breadcrumb.Item>
                    )


            }

        })
    }
    getItem(list)
    return arr
}

//左侧栏默认展开项
export const filterRoutes = pathname => {
    let pathSnippets = pathname.split('/').filter(path => path)
    let paths = pathSnippets.map((path, index) => `/${pathSnippets.slice(0, index + 1).join('/')}`)
    paths.splice(0,1)
    return paths
}

//重定向到children的第一个路由
export function recursionRouterTwo(userRouter = [], allRouter = []) {
    const realRoutes = allRouter
        .filter(item => userRouter.includes(item.path))
        .map(item =>{
            return {
                ...item,
                redirect:item.children?item.children[0].path:null,
                children: item.children
                    ? recursionRouterTwo(userRouter, item.children)
                    : null
            }
        })
    return realRoutes

}
