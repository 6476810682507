import service from "./index";
// import servicePath from "./apiUrl";
//未统一封装 有些接口比较累赘
//login
export function Login(data){
    return service({
        url: '/api/auth/jwt/token/',
        method: 'post',
        data: data
    })
}

//企业信息
export function company_info(){
    return service({
        url: '/api/auth/company_info/',
        method: 'get',
    })
}

//修改企业信息
export function update_company_info(id,params){
    return service({
        url: '/api/auth/company_info/'+id+'/',
        method: 'put',
        data:params
    })
}

// //碳资产
// export function wb_carbon(){
//     return service({
//         url: '/api/carbonasse/carbonasse/',
//         method: 'get',
//     })
// }
//
// //创建配额管理
// export function wb_creatquotamanage(data){
//     return service({
//         url: '/api/carbonasse/quotamanage/',
//         method: 'post',
//         data
//     })
// }

//配额管理
export function wb_quotamanagelist(){
    return service({
        url: '/api/carbonasse/quotamanagelist/',
        method: 'get',
    })
}
//删除配额管理
export function wb_DeleteQuotaManageList(id,state){
    return service({
        url: '/api/carbonasse/quotamanage/',
        method: 'DELETE',
        data:{
            id:id,
            state:state
        }
    })
}

//修改配额状态
export function wb_UpdateQuotaManageList(id,state){
    return service({
        url: '/api/carbonasse/quotamanage/',
        method: 'DELETE',
        data:{
            id:id,
            state:state
        }
    })
}




//CCer管理
export function wb_ccermanagelist(){
    return service({
        url: '/api/carbonasse/ccermanagelist/',
        method: 'get',
    })
}

//创建CCer
export function wb_Creatccermanagelist(data){
    return service({
        url: '/api/carbonasse/ccermanage/',
        method: 'post',
        data
    })
}

// //删除CCEr
// export function wb_DeleteCcerManagelist(id){
//     return service({
//         url: '/api/carbonasse/ccermanage/',
//         method: 'DELETE',
//         data:{
//             id:id,
//             state:3
//         }
//     })
// }

//修改CCer状态
export function wb_UpdateCcerManagelist(id,state){
    return service({
        url: '/api/carbonasse/ccermanage/',
        method: 'DELETE',
        data:{
            id:id,
            state:state
        }
    })
}




//创建报告

export function qr_creatreport(params){
    return service({
        url:'/api/report/report/',
        method:'post',
        data:params
    })
}
//获取报告list
export function qr_getreportlist(){
    return service({
        url:'/api/report/report/',
        method:'get',
    })
}
//删除报告list
export function qr_deleteReportlist(id){
    return service({
        url:'/api/report/report/'+id+'/',
        method:'DELETE',
    })
}

//获取机组list
export function qr_getCrewlist(type){
    return service({
        url:'/api/crew/report_crew/?type='+type+'',
        method:'get',
    })
}

//查看机组信息
export function qr_checkCrewlist(id){
    return service({
        url:'/api/crew/report_crew/'+id+'/',
        method:'get',
    })
}

//更新机组信息
export function qr_UpdateCrewlist(id,params){
    return service({
        url:'/api/crew/report_crew/'+id+'/',
        method:'put',
        data:params
    })
}

//删除机组
export function qr_deleteCrewlist(id){
    return service({
        url:'/api/crew/report_crew/'+id+'/',
        method:'DELETE',
    })
}

//创建机组
export function qr_CreatCrewlist(prams){
    return service({
        url:'/api/crew/report_crew/',
        method:'POST',
        data:prams
    })
}

// 获取机组信息--燃料名称
export function qr_getfuelname(){
    return service({
        url:'/api/crew/fuelname/',
        method:'get',
    })
}

// 获取机组信息-- 获取机组信息--燃料类型
export function qr_getfueltype(){
    return service({
        url:'/api/crew/fueltype/',
        method:'get',
    })
}

//获取电力排放每个场景当前机组信息
export function qr_getReport_crew(id){
    return service({
        url:'/api/crew/report_crew/report_crew/',
        method:'post',
        data:{
            power_report_id:id
        }
    })
}
//获取化石燃料燃料品种信息
export function qr_getFossil_crew(){
    return service({
        url:'/api/fossil/fuel/',
        method:'get',
    })
}

//创建化石燃料
export function qr_creatFossil_crew(data){
    return service({
        url:'/api/fossil/fossilfuel/',
        method:'post',
        data
    })
}

//获取化石燃料列表 参数id mouth
export function qr_getFossil_crewList(month,id){
    return service({
        url:'/api/fossil/fossilfuel/?month='+month+'&power_report_id='+id+'',
        method:'GET',
    })
}

//查看化石燃料
export function qr_checkFossil_crewList(id){
    return service({
        url:'api/fossil/fossilfuel/'+id+'/',
        method:'GET',
    })
}

//更新化石燃料
export function qr_UpdateFossil_crewList(id,params){
    return service({
        url:'api/fossil/fossilfuel/'+id+'/',
        method:'put',
        data:params
    })
}
//删除化石燃料数据 参数id
export function qr_DeleteFossil_crewList(id){
    return service({
        url:'/api/fossil/fossilfuel/'+id+'/',
        method:'DELETE',
    })
}


//创建购入电力排放
export function qr_PowerList(data){
    return service({
        url:'/api/power/powerdata/',
        method:'post',
        data
    })
}

//获取电网排放因子
export function qr_getFactorList(){
    return service({
        url:'/api/power/factor/',
        method:'get',
    })
}

//查看电网因子
export function qr_checkFactorList(id){
    return service({
        url:'/api/power/powerdata/'+id+'/',
        method:'get',
    })
}
//更新电网因子
export function qr_UpdateFactorList(id,params){
    return service({
        url:'/api/power/powerdata/'+id+'/',
        method:'put',
        data:params
    })
}


//获取电力排放数据list
export function qr_getPowerDateList(month,id){
    return service({
        url:'/api/power/powerdata/?month='+month+'&power_report_id='+id+'',
        method:'get',
    })
}
//
//删除电力排放数据list 参数id
export function qr_deletePowerDateList(id){
    return service({
        url:'/api/power/powerdata/'+id+'/',
        method:'DELETE',
    })
}


//获取生产数据list
export function qr_productionDataList(month,id){
    return service({
        url:'/api/produc/productiondata/?month='+month+'&power_report_id='+id+'',
        method:'get',
    })
}

//创建生产数据及排放
export function qr_CreatProdDataList(data){
    return service({
        url:'/api/produc/productiondata/',
        method:'post',
        data
    })
}

//删除生产数据及排放
export function qr_DeleteProdDataList(id){
    return service({
        url:'/api/produc/productiondata/'+id+'/',
        method:'DELETE',
    })
}

//查看电网因子
export function qr_CheckProdDataList(id){
    return service({
        url:'/api/produc/productiondata/'+id+'/',
        method:'get',
    })
}

//更新电网因子
export function qr_UpdateProdDataList(id,params){
    return service({
        url:'/api/produc/productiondata/'+id+'/',
        method:'put',
        data:params
    })
}


//检测方法g获取list
export function qr_getMonitorDataList(month,id){
    return service({
        url:'/api/monitor/monitor/?month='+month+'&power_report_id='+id+'',
        method:'get',
    })
}

//创建检测方法
export function qr_CreatMonitorDataList(data){
    return service({
        url:'/api/monitor/monitor/',
        method:'post',
        data
    })
}

export function qr_DeleteMonitorDataList(id){
    return service({
        url:'/api/monitor/monitor/'+id+'/',
        method:'DELETE',
    })
}

//查看检测方法
export function qr_CheckMonitorDataList(id){
    return service({
        url:'/api/monitor/monitor/'+id+'/',
        method:'get',
    })
}

//更新检测方法
export function qr_UpdateMonitorDataList(id,params){
    return service({
        url:'/api/monitor/monitor/'+id+'/',
        method:'put',
        data:params
    })
}

//填报数据图表
export function qr_DatasumList(id){
    return service({
        url:'/api/data/datasum/?power_report_id='+id+'',
        method:'get',
    })
}


//碳交易管理-交易列表
export function wb_getTradList(){
    return service({
        url:'/api/carbonasse/transactions/',
        method:'get',
    })
}

//碳交易管理-查看交易列表
export function wb_readTradList(id){
    return service({
        url:'/api/carbonasse/transactions/'+id+'/',
        method:'get',
    })
}
//交易列表删除
export function wb_DeleteTradList(id){
    return service({
        url:'/api/carbonasse/transactions/',
        method:'Delete',
        data:{
            id:id
        }
    })

}


//更新交易列表
export function wb_UpdateTradList(id,params){
    return service({
        url:'/api/carbonasse/transactions/'+id+'/',
        method:'put',
        data:params
    })
}


//碳交易管理-创建交易列表
export function wb_CreatTradList(params){
    return service({
        url:'/api/carbonasse/createtransaction/',
        method:'post',
        data:params
    })
}

//创建交易分析
export function wb_CreatTradAnalysis(params){
    return service({
        url:'/api/carbonasse/analysiseesults/',
        method:'post',
        data:params
    })
}

//删除交易分析

export function wb_DeleteTradAnalysis(id){
    return service({
        url:'/api/carbonasse/analysisa/',
        method:'delete',
        data:{
            id:id
        }

    })
}

//获取交易分析列表
export function wb_GetTradAnalysis(){
    return service({
        url:'/api/carbonasse/analysisa/',
        method:'get',
    })
}

//查看交易分析
export function wb_CheckTradAnalysis(id){
    return service({
        url:'/api/carbonasse/analysisa/'+id+'/',
        method:'get',
    })
}

//更新交易分析
export function wb_UpdateTradAnalysis(id,params){
    return service({
        url:'/api/carbonasse/analysisa/'+id+'/',
        method:'put',
        data:params
    })
}

//获取交易分析结果
export function wb_GetTradResult(){
    return service({
        url:'/api/carbonasse/analysiseesults/',
        method:'get',
    })
}

//获取交易匹配list
export function wb_GetTradMatch(){
    return service({
        url:'/api/carbonasse/ransactionmatching/',
        method:'get',
    })
}

//创建交易匹配
export function wb_CreatTradMatch(params){
    return service({
        url:'/api/carbonasse/transaction/',
        method:'post',
        data:params
    })
}

//交易匹配删除
export function wb_DeleteTradMatch(id){
    return service({
        url:'/api/carbonasse/ransactionmatching/',
        method: 'DELETE',
        data:{
            id:id,
        }
    })
}


//查看交易匹配
export function wb_CheckTradMatch(id){
    return service({
        url:'/api/carbonasse/ransactionmatching/'+id+'/',
        method:'get',
    })
}

//更新交易匹配
export function wb_UpdateTradMatch(id,params){
    return service({
        url:'/api/carbonasse/ransactionmatching/'+id+'/',
        method:'put',
        data:params
    })
}




//匹配推荐企业名录
export function wb_getTradMatchCompany(){
    return service({
        url:'/api/carbonasse/transactioncompany/',
        method:'get',
    })
}


//碳减排计划创建
export function wb_CreatReductionPlan(params){
    return service({
        url:'/api/carbon_reduction/plan/',
        method:'post',
        data:params
    })
}

//碳减排计划删除
export function wb_DeleteReductionPlan(id){
    return service({
        url:'/api/carbon_reduction/plan/'+id+'/',
        method:'delete',
    })
}

//碳减排计划更新
export function wb_UpdateReductionPlan(id,params){
    return service({
        url:'/api/carbon_reduction/plan/'+id+'/',
        method:'put',
        data:params
    })
}

//查看减排计划
export function wb_CheckReductionPlan(id){
    return service({
        url:'/api/carbon_reduction/plan/'+id+'/',
        method:'get',
    })
}
//碳减排计划获取列表
export function wb_GetReductionPlan(){
    return service({
        url:'/api/carbon_reduction/plan/',
        method:'get',
    })
}


//碳减排生产计划列表
export function wb_GetProductionPlan(){
    return service({
        url:'/api/carbon_plan/detail/',
        method:'get',
    })
}

//碳减排生产计划查看
export function wb_CheckProductionPlan(id){
    return service({
        url:'/api/carbon_plan/detail/'+id+'/',
        method:'get',
    })
}

//更新生产计划查看
export function wb_UpdateProductionPlan(id,params){
    return service({
        url:'/api/carbon_plan/detail/'+id+'/',
        method:'put',
        data:params
    })
}


//碳减排生产计划创建
export function wb_CreatProductionPlan(params){
    return service({
        url:'/api/carbon_plan/detail/',
        method:'post',
        data:params
    })
}

//碳减排生产计划删除
export function wb_DeleteProductionPlan(id){
    return service({
        url:'/api/carbon_plan/detail/'+id+'/',
        method:'delete',
    })
}


//珍义_配额管理
//配额管理list获取
export function wb_GetCarbonQuota(){
    return service({
        url:'/api/carbon_assets/CarbonQuota/',
        method:'get',
    })
}

export function wb_CreatCarbonQuota(prams){
    return service({
        url:'/api/carbon_assets/CarbonQuota/',
        method:'post',
        data:prams
    })
}

export function wb_DeleteCarbonQuota(id){
    return service({
        url:'/api/carbon_assets/CarbonQuota/'+id+'/',
        method:'delete',
    })
}

export function wb_UpdateCarbonQuota(id,params){
    return service({
        url:'/api/carbon_assets/CarbonQuota/'+id+'/',
        method:'put',
        params
    })
}

//查看配额list
export function wb_CheckCarbonQuota(id){
    return service({
        url:'/api/carbon_assets/CarbonQuota/'+id+'/',
        method:'get',
    })
}

//珍义——CCer管理
export function wb_GetCCERAssets(){
    return service({
        url:'/api/carbon_assets/CCERAssets/',
        method:'get',
    })
}

//删除CCER
export function wb_DeleteCCERAssets(id){
    return service({
        url:'/api/carbon_assets/CCERAssets/'+id+'/',
        method:'delete',
    })
}

//查看CCEr
export function wb_CheckCCERAssets(id){
    return service({
        url:'/api/carbon_assets/CCERAssets/'+id+'/',
        method:'get',
    })
}

//创建CCER
export function CreatCCERAssets(params){
    return service({
        url:'/api/carbon_assets/CCERAssets/',
        method:'post',
        data:params
    })
}

//更新CCEr
export function UpCCERAssets(id,params){
    return service({
        url:'/api/carbon_assets/CCERAssets/'+id+'/',
        method:'put',
        data:params
    })
}

//珍义-资金预算

export function wb_GetCapitalBudget(){
    return service({
        url:'/api/carbon_assets/CapitalBudget/',
        method:'get',
    })
}

//删除资金预算
export function wb_DeleteCapitalBudget(id){
    return service({
        url:'/api/carbon_assets/CapitalBudget/'+id+'/',
        method:'delete',
    })
}

//查看资金预算
export function wb_CheckCapitalBudget(id){
    return service({
        url:'/api/carbon_assets/CapitalBudget/'+id+'/',
        method:'get',
    })
}

//创建资金预算
export function CreatCapitalBudget(params){
    return service({
        url:'/api/carbon_assets/CapitalBudget/',
        method:'post',
        data:params
    })
}

//更新资金预算
export function UpCapitalBudget(id,params){
    return service({
        url:'/api/carbon_assets/CapitalBudget/'+id+'/',
        method:'put',
        data:params
    })
}


//珍义-资产管理

export function wb_GetCarbonAssets(){
    return service({
        url:'/api/carbon_assets/CarbonAssets/',
        method:'get',
    })
}

//删除资产管理
export function wb_DeleteCarbonAssets(id){
    return service({
        url:'/api/carbon_assets/CarbonAssets/'+id+'/',
        method:'delete',
    })
}

//查看资产管理
export function wb_CheckCarbonAssets(id){
    return service({
        url:'/api/carbon_assets/CarbonAssets/'+id+'/',
        method:'get',
    })
}

//创建资产管理
export function CreatCarbonAssets(params){
    return service({
        url:'/api/carbon_assets/CarbonAssets/',
        method:'put',
        data:params
    })
}

//更新资产管理
export function UpCarbonAssets(id,params){
    return service({
        url:'/api/carbon_assets/CarbonAssets/'+id+'/',
        method:'put',
        data:params
    })
}

//齐然——月份汇总数据
export function SumAssets(id){
    return service({
        url:'/api/data/datasum/?power_report_id='+id+'',
        method:'get',
    })
}


//机组类型细分
export function CrewType(){
    return service({
        url:'/api/crew/crewtype/',
        method:'get',
    })
}

//计算化石燃料燃烧-参数计算
export function ParamCal(calculationformula,data){
    return service({
        url:'/api/fossil/calculation/?calculationformula='+calculationformula+'&'+data+'',
        method:'get',
    })
}

//生产数据供热量计算
export function Heat_Calculation(data){
    return service({
        url:'/api/produc/calculation/',
        method:'POST',
        data
    })
}

//用户菜单接口
export function getMenu(){
    return service({
        url:'api/auth/build/menus/',
        method:'get',
    })
}

//碳价格预测
export function getCarbonprice(){
    return service({
        url:'/api/carbon_price/carbon_price/',
        method:'get',
    })
}

//三级用户首页
export function getInfo(){
    return service({
        url:'/api/index/info',
        method:'get',
    })
}

//三级首页数据统计
export function getDatalist(){
    return service({
        url:'/api/produc/productiondataph/',
        method:'get',
    })
}

//集团信息组织管理
export function getCompany(){
    return service({
        url:'/api/auth/company_info/get_info/',
        method:'get',
    })
}


//下载报告
export function getReport(id){
    return service({
        url:'/api/report/download/'+id+'/',
        method:'get',
    })
}



