import React from "react";
import 'antd/dist/antd.css';
import {Input, Button, Form, Row, Col, message} from 'antd';
import {Divider} from 'antd';
import {UserOutlined, UnlockOutlined} from '@ant-design/icons';
import {Login, company_info} from "../../api/api";
import cookie from 'react-cookies'
import './login.css'
import login from '../../login.png'

export default class NormalLoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            homeLink: "herder-home",
            homeMounted: true,
            user: '',
            password: ''
        }
    }

    onClick = () => {
        let data = {
            username: this.state.user,
            password: this.state.password
        }
        Login(data).then(res => {
            if (res.status === 200) {
                cookie.save('token', res.data.token)
                company_info().then(e => {
                    if (e.status === 200) {
                        cookie.save('roles', e.data.results[0].group_name);
                        return (
                            e.data.results[0].group_name === '集团用户' ?
                                this.props.history.replace('/user/panorama') : this.props.history.replace('/user')
                        )

                    }
                    if (e.status === 400) {
                        message.error('登录失败')
                    }

                })
                //
            }
        })
    };
    ChangeUser = (e) => {
        console.log(e.target.value)
        this.setState({
            user: e.target.value
        })
    }
    ChangePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }


    render() {
        return (
            <div className="loginContentForm">
                <div className="loginContent">
                    <img
                        className="pic1"
                        src={login}
                        alt="中国**能源集团有限公司"/>
                    <Divider style={{margin: 0, padding: 0, marginBottom: '10px', fontSize: '20px'}}>碳排放管理平台</Divider>
                    <div style={{marginTop: 40, marginBottom: 16}}>
                        <Form>
                            <Row span={24}>
                                <Col span={24}>
                                    <Form.Item name='username'
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: '必填项'
                                                   },
                                               ]}>
                                        <Input className="inputUser" onChange={this.ChangeUser} value={this.state.user}
                                               addonBefore={<UserOutlined/>}/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name='password'
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: '必填项'
                                                   },
                                               ]}>
                                        <Input.Password className="inputUser" onChange={this.ChangePassword}
                                                        value={this.state.password}
                                                        addonBefore={<UnlockOutlined/>}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button type="primary" htmlType="submit" onClick={this.onClick}
                                    className="login-form-button">
                                登录
                            </Button>
                        </Form>

                        {/*<Checkbox>记住密码</Checkbox>*/}
                    </div>
                </div>
            </div>
        );
    }
}
