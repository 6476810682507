import AsyncComponent from '../utils/asyncComponent'
import NormalLoginForm from '../pages/login/login'
import PanoramaIndex from '../pages/Panorama/Panorama'
import CrewInfo from "../components/CoalMiningManagement/Crew/Crew";//机组信息、
import FillInfo from "../components/CoalMiningManagement/Crew/Fill";
//碳交易管理-分析详情

const UnitInfo = AsyncComponent(() => import('../components/CoalMiningManagement/unitInfo/unitInfo')) //单位信息
const Home = AsyncComponent(() => import('../components/Home/Home'))
const Cem = AsyncComponent(() => import('../components/CoalMiningManagement/index'))//碳排放管理
const allReport = AsyncComponent(() => import('../components/CoalMiningManagement/allReport/allReport'))//碳排放管理-报告管理
const Cam = AsyncComponent(() => import('../components/CarbonAssetManagement/index'))//碳资产管理
const BorderManagement = AsyncComponent(() => import('../components/BorderManagement/BorderManagement'))//集团边界管理
const CarbonEmissionStatistics = AsyncComponent(() => import('../components/CarbonEmissionStatistics/CarbonEmissionStatistics'))//碳排放数据统计
const KeyCombustionFacilities = AsyncComponent(() => import('../components/CoalMiningManagement/KeyCombustionFacilities/KeyCombustionFacilities'))//煤炭开采-重点设施燃烧
const OtherFacilitiesBurning = AsyncComponent(() => import('../components/CoalMiningManagement/OtherFacilitiesBurning/OtherFacilitiesBurning'))//煤炭开采-其他燃烧
const KeyTorchBurning = AsyncComponent(() => import('../components/CoalMiningManagement/KeyTorchBurning/KeyTorchBurning'))//煤炭开采-火炬燃烧
const KeyCO4Mining = AsyncComponent(() => import('../components/CoalMiningManagement/KeyCO4Mining/KeyCO4Mining'))//煤炭开采-co4
const KeyCO2Mining = AsyncComponent(() => import('../components/CoalMiningManagement/KeyCO2Mining/KeyCO2Mining'))//煤炭开采-co2
const KeyOpenPitMining = AsyncComponent(() => import('../components/CoalMiningManagement/KeyOpenPitMining/KeyOpenPitMining'))//煤炭开采-露天开采
const KeyPostMineActivities = AsyncComponent(() => import('../components/CoalMiningManagement/KeyPostMineActivities/KeyPostMineActivities'))//煤炭开采-矿后活动
const KeyElectricitySteamWater = AsyncComponent(() => import('../components/CoalMiningManagement/KeyElectricitySteamWater/KeyElectricitySteamWater'))//煤炭开采-净购电力
const KeyEmissionSummary = AsyncComponent(() => import('../components/CoalMiningManagement/KeyEmissionSummary/KeyEmissionSummary'))//煤炭开采-排放量汇总
const FossilFuelCombustion = AsyncComponent(() => import('../components/ElectricityProductionManagement/FossilFuelCombustion/FossilFuelCombustion'))//电力生产-化石燃料
const PurchaseOfElectricity = AsyncComponent(() => import('../components/ElectricityProductionManagement/PurchaseOfElectricity/PurchaseOfElectricity'))//电力生产-购入电力
const ProductionDataEmissionSummary = AsyncComponent(() => import('../components/ElectricityProductionManagement/ProductionDataEmissionSummary/ProductionDataEmissionSummary'))//电力生产-生产数据
const CalorificValue = AsyncComponent(() => import('../components/ElectricityProductionManagement/CalorificValue/CalorificValue')) //电力生产-监测方法
const CapitalBudgetPage = AsyncComponent(() => import('../components/CarbonAssetManagement/CarbonAssetStatistics/CarbonAssetStatistics')) //碳资产-资金预算

const CCerTrading = AsyncComponent(() => import('../components/CarbonAssetManagement/Ccertrading/Ccertrading'))  //碳资产-ccer交易管理

const OnPriceQuotation = AsyncComponent(() => import('../components/OnPriceQuotation/OnPriceQuotation')) //碳价
const EditReport = AsyncComponent(() => import('../components/CoalMiningManagement/allReport/creatReport/CreatReport')) //填写报告
const AssetManagement = AsyncComponent(() => import('../components/CarbonAssetManagement/CreatCQTM/CreatCQTM')) //碳资产-资产管理
const PerformanceManagementPage=AsyncComponent(() => import('../components/CarbonAssetManagement/PerformanceManagement/PerformanceManagement')) //碳资产-履约管理


const CarbonManagementPage = AsyncComponent(() => import('../components/CarbonEmissionManagement/index'))//碳排放管理
const ReductionPlanPage = AsyncComponent(() => import('../components/CarbonEmissionManagement/ReductionPlan/ReductionPlan')) //碳排放管理-减排计划
const ProductionPlanPage = AsyncComponent(() => import('../components/CarbonEmissionManagement/ProductionPlan/ProductionPlan'))//碳排放管理-生产计划

const TradingPage = AsyncComponent(() => import('../components/TradingManagement/index'))//碳交易管理
const TradingMacthPage = AsyncComponent(() => import('../components/TradingManagement/Mataching/Matching'))//碳交易管理-交易匹配
const ListingPage = AsyncComponent(() => import('../components/TradingManagement/Listing/Listing'))//碳交易管理-交易列表
const AnalysisPage = AsyncComponent(() => import('../components/TradingManagement/Analysis/Analysis'))//碳交易管理-交易分析

const RecommendedEnterprisesPage = AsyncComponent(() => import('../components/CarbonEmissionManagement/RecommendedEnterprises/RecommendedEnterprises'))//碳交易管理-推荐企业名录
const AnalysisResult = AsyncComponent(() => import('../components/TradingManagement/Analysis/result'))//碳交易管理-分析详情

const ForecastPage = AsyncComponent(() => import('../components/CarbonAssetManagement/ForecastManagement/ForecastManagement'))//碳交易管理-分析详情

// const AuditingPage = AsyncComponent(() => import('../components/Audit/Audit'))

const routes = [
    {
        path: '/user/panorama',
        title: '全景图',
        icon: 'DesktopOutlined',
        component: PanoramaIndex,
        hidden: true,
        exact: true,
    },
    {
        path: '/login',
        title: '登录',
        icon: 'DesktopOutlined',
        component: NormalLoginForm,
        hidden: true,
    },
    {
        path: '/user/index',
        pathname: 'user',
        title: '首页',
        icon: 'DesktopOutlined',
        component: Home,
        exact: true,
    },
    {
        path: '/user/BorderManagement',
        pathname: 'BorderManagement',
        component: BorderManagement,
        title: '集团边界管理',
        icon: 'DesktopOutlined',
        role:['集团用户'],
    },
    {
        path: '/user/CM',
        component: Cem,
        title: '碳排放报告管理',
        icon: 'DesktopOutlined',
        role:['三级用户','二级用户'],
        children: [
            {
                path: '/user/CM/editReport',
                pathName: 'editReport',
                component: EditReport,
                hidden: true,
                title: '填写报告',
                role:['三级用户'],
            },
            {
                path: '/user/CM/Information',
                pathName: 'Information',
                // eslint-disable-next-line no-undef
                component: UnitInfo,
                title: '单位信息',
            },
            {
                path: '/user/CM/basicInfo',
                title: '设施信息',
                pathname: 'basicInfo',
                icon: 'DesktopOutlined',
                role:['三级用户'],
                children: [
                    {
                        path: '/user/CM/basicInfo/CrewInfo',
                        pathName: 'CrewInfo',
                        // eslint-disable-next-line no-undef
                        component: CrewInfo,
                        title: '机组信息录入',
                    },
                    {
                        path: '/user/CM/basicInfo/fill',
                        pathName: 'fill',
                        // eslint-disable-next-line no-undef
                        component: FillInfo,
                        title: '填报单位录入',
                    },
                ]
            },
            {
                path: '/user/CM/allReport',
                pathName: 'allReport',
                component: allReport,
                title: '数据管理',
            },
            {
                path: '/user/CM/CoalMining',
                pathname: 'CoalMining',
                title: '煤炭开采排放报告管理',
                icon: 'DesktopOutlined',
                role:['三级用户',],
                // hidden: true,
                children: [
                    {
                        path: '/user/CM/CoalMining/KeyCombustionFacilities',
                        pathName: 'KeyCombustionFacilities',
                        component: KeyCombustionFacilities,
                        title: '重点燃烧设施',
                    },
                    {
                        path: '/user/CM/CoalMining/OtherFacilitiesBurning',
                        pathName: 'OtherFacilitiesBurning',
                        component: OtherFacilitiesBurning,
                        title: '其他燃烧设施',
                        hidden: true,
                    },
                    {
                        path: '/user/CM/CoalMining/KeyTorchBurning',
                        pathName: 'KeyTorchBurning',
                        component: KeyTorchBurning,
                        title: '火炬燃烧',
                    },
                    {
                        path: '/user/CM/CoalMining/KeyCO4Mining',
                        pathName: 'KeyCO4Mining',
                        component: KeyCO4Mining,
                        title: '井工开采CH4',
                    },
                    {
                        path: '/user/CM/CoalMining/KeyCO2Mining',
                        pathName: 'KeyCO2Mining',
                        component: KeyCO2Mining,
                        title: '井工开采CO2',
                    },
                    {
                        path: '/user/CM/CoalMining/KeyOpenPitMining',
                        pathName: 'KeyOpenPitMining',
                        component: KeyOpenPitMining,
                        title: '露天开采',
                    },
                    {
                        path: '/user/CM/CoalMining/KeyPostMineActivities',
                        pathName: 'KeyPostMineActivities',
                        component: KeyPostMineActivities,
                        title: '矿后活动',
                    },
                    {
                        path: '/user/CM/CoalMining/KeyElectricitySteamWater',
                        pathName: 'KeyElectricitySteamWater',
                        component: KeyElectricitySteamWater,
                        title: '净购电力、蒸汽、热水',
                    },
                    {
                        path: '/user/CM/CoalMining/KeyEmissionSummary',
                        pathName: 'KeyEmissionSummary',
                        component: KeyEmissionSummary,
                        title: '排放量汇总',
                    }
                ]
            },
            {
                path: '/user/CM/EM',
                title: '电力生产排放报告管理',
                icon: 'DesktopOutlined',
                hidden: true,
                children: [
                    {
                        path: '/user/CM/EM/FossilFuelCombustion/:id',
                        pathName: 'FossilFuelCombustion',
                        component: FossilFuelCombustion,
                        title: '化石燃料燃烧',
                    },
                    {
                        path: '/user/CM/EM/PurchaseOfElectricity/:id',
                        pathName: 'PurchaseOfElectricity',
                        component: PurchaseOfElectricity,
                        title: '购入使用电力排放',
                    },
                    {
                        path: '/user/CM/EM/ProductionDataEmissionSummary/:id',
                        pathName: 'ProductionDataEmissionSummary',
                        component: ProductionDataEmissionSummary,
                        title: '生产数据及排放量汇总',
                    },
                    {
                        path: '/user/CM/EM/CalorificValue/:id',
                        pathName: 'CalorificValue',
                        component: CalorificValue,
                        title: '监测方法',
                    },
                ]
            }
        ]
    },
    {
        path: '/user/CarbonEmissionStatistics',
        pathname: 'CarbonEmissionStatistics',
        component: CarbonEmissionStatistics,
        title: '碳排放数据统计',
        icon: 'DesktopOutlined',
        role:['集团用户'],
    },
    {
        path: '/user/CAM',
        component: Cam,
        title: '碳资产管理',
        icon: 'DesktopOutlined',
        role:['集团用户','三级用户'],
        children: [
            {
                path: '/user/CAM/Forecast_management',
                pathname: 'Forecast_management',
                component: ForecastPage,
                title: '配额管理',
            },
            {
                path: '/user/CAM/CCer',
                pathname: 'CCer',
                component: CCerTrading,
                title: 'CCER管理',
            },
            {
                path: '/user/CAM/Capital_budget',
                pathname: 'Capital_budget',
                component: CapitalBudgetPage,
                title: '资金预算',
            },
            {
                path: '/user/CAM/AssetManagement',
                pathname: 'AssetManagement',
                component: AssetManagement,
                title: '资产管理',
            },
            {
                path: '/user/CAM/PerformanceManagement',
                pathname: 'PerformanceManagement',
                component: PerformanceManagementPage,
                title: '履约管理',
            },
        ]
    },
    {
        path: '/user/Trad',
        pathName: 'Trad',
        component: TradingPage,
        title: '碳交易管理',
        icon: 'DesktopOutlined',
        role:['集团用户','三级用户'],
        children: [{
            path: '/user/Trad/Matching',
            pathName: 'Matching',
            component: TradingMacthPage,
            title: '交易匹配',
        },
            {
                path: '/user/Trad/Recommended',
                pathName: 'Recommended',
                component: RecommendedEnterprisesPage,
                title: '推荐企业名录',
                hidden: true
            },
            {
                path: '/user/Trad/Listing',
                pathName: 'Listing',
                component: ListingPage,
                title: '交易列表',
            },
            {
                path: '/user/Trad/Analysis',
                pathName: 'Analysis',
                component: AnalysisPage,
                title: '交易分析',
                icon: 'DesktopOutlined',
            },
            {
                path: '/user/Trad/AnalysisResult',
                pathName: 'AnalysisResult',
                component: AnalysisResult,
                title: '交易分析详情',
                hidden: true
            }
        ]
    },
    {
        path: '/user/EM',
        pathName: 'EM',
        title: '碳减排管理',
        icon: 'DesktopOutlined',
        role:['集团用户','三级用户'],
        component: CarbonManagementPage,
        children: [{
            path: '/user/EM/ReductionPlan',
            pathName: 'ReductionPlan',
            component: ReductionPlanPage,
            title: '减排计划',
        },
            {
                path: '/user/EM/ProductionPlan',
                pathName: 'ProductionPlan',
                component: ProductionPlanPage,
                title: '生产计划',
            },
        ]
    },
    {
        path: '/user/OnPriceQuotation',
        pathName: 'OnPriceQuotation',
        component: OnPriceQuotation,
        title: '碳价格管理',
    },
]
export default routes
