import './App.css';
import 'antd/dist/antd.css';
import React, {Component} from "react";
import NormalLoginForm from './pages/login/login'
import IndexContent from './pages/index/index'
import PanoramaIndex  from './pages/Panorama/Panorama'
import {HashRouter as Router, Route, Switch,} from 'react-router-dom'

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>{/*只匹配其中一个 */}
                    <Route exact path='/' component={NormalLoginForm}></Route>
                    <Route exact path='/user/panorama' component={PanoramaIndex}></Route>
                    <Route path='/user' component={IndexContent}></Route>
                </Switch>
            </Router>
        )
            ;
    }
}

export default App;
