import axios from 'axios'
import {getToken,removeToken} from "./auth";
import {message} from "antd";

// 创建axios实例
const service = axios.create({
   // baseURL: 'http://124.127.178.242:8000', // api的base_url
    //  baseURL: 'http://124.71.175.41:8001/',
    baseURL: 'https://api.carbonmanager.com.cn/',
    timeout: 5000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(config => {
    // eslint-disable-next-line no-undef
    const token = getToken()
     if (token) {
         // eslint-disable-next-line
         config.headers.Authorization = 'JWT '+`${token}`
     }
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    // Promise.reject(error)
})

// response拦截器
service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            // eslint-disable-next-line default-case
            switch (error.response.status) {
                case 400:
                    message.error('网络错误')
                    break;
                case 403:
                    message.error('信息过期')
                    removeToken()
                    setTimeout(function (){
                        window.open('/')
                    },2000)
                    break;
            }
        }
        return Promise.reject(error.response)
    })

export default service
