import React from "react";
import {Button, Col, message, Popconfirm, Row, Table, Form, Input, Select, Drawer, Divider} from "antd";
import 'antd/dist/antd.css';
import {PlusOutlined} from "@ant-design/icons";
import {
    qr_getCrewlist,
    qr_deleteCrewlist,
    qr_getfuelname,
    qr_getfueltype,
    qr_CreatCrewlist,
    qr_UpdateCrewlist,
    CrewType
} from '../../../api/api'
import cookie from 'react-cookies'

export default class FillInfo extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            subdivide_list: [],
            Crew_typeList: [],
            loading: false,
            selectedRowKeys: [],
            visible: false,
            edit_status: false,//编辑图层状态按钮
            table_status: true,//表格状态编辑or展示 true代表展示 false代表新增
            crewList: [],
            getFuelNameList: [],//燃料名称arr
            getFuelTypeList: [],//燃料类型arr
            editData_source: [ //编辑表格内容
                {
                    type_name: 'crew_name', //name值 机组名称
                    name: '机组名称', //label值
                    value: '',
                    type: 'text',
                    rules: true,
                    disabled: true
                },
                {
                    type_name: 'crew_type', //name值 机组类型
                    name: '机组类型', //label值
                    value: '',
                    type: 'crew_type',
                    rules: true
                },
                {
                    type_name: 'subdivide', //name值 机组类型
                    name: '机组类型细分', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'power_fuel', //name值 发电燃料类型
                    name: '发电燃料类型', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'fuel_name', //name值 燃料
                    name: '燃料名称', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'source', //name值 数据来源
                    name: '数据来源', //label值
                    value: '默认值',
                    type: 'text',
                    rules: false
                }
            ],
        }
    }

    componentDidMount() {
        this.getCrewList()
        this.getCrewType()
    }

    QXlist = () => {
        this.setState({
            table_status: true
        })
    }
    //获取机组类型细分
    getCrewType = () => {
        CrewType().then(res => {
            if (res.status === 200) {
                this.setState({
                    Crew_typeList: res.data,
                    subdivide_list: res.data[0].subdivide
                })
            }
        })
    }
    //获取机组信息
    getCrewList = () => {
        qr_getCrewlist('填报单位').then(res => {
            if (res.status === 200) {
                if (res.data.results.length > 0) {
                    this.setState({
                        list: res.data.results
                    })
                    cookie.save('crew_status', 'true')
                }
            }
        })
    }

    //删除机组
    deleteReport = (e) => {
        qr_deleteCrewlist(e).then(res => {
            if (res.status === 200) {
                message.success('删除成功')
                this.getCrewList()
            }
        })
    }

    //取消删除
    cancel(e) {
        message.error('取消删除');
    }

    //获取燃料名称
    getFuelName = () => {
        qr_getfuelname().then(res => {
            if (res.status === 200) {
                this.setState({
                    getFuelNameList: res.data
                })
            }
        })
    }
    //发电燃料类型
    getFuelType = () => {
        qr_getfueltype().then(res => {
            if (res.status === 200) {
                this.setState({
                    getFuelTypeList: res.data
                })
            }
        })
    }

    //新增表格行
    AddTableRow() {
        this.getFuelName()
        this.getFuelType()
        if (this.state.selectedRowKeys.length <= 1) {
            message.error('请合并多个机组信息')
        } else {
            let s = [];
            let str=''
            for (let i = 0; i < this.state.selectedRowKeys.length; i++) {
                for(let j=0;j<this.state.list.length;j++){
                    if(this.state.selectedRowKeys[i]===this.state.list[j].key){
                        s.push(this.state.list[j].id)
                        str+=this.state.list[j].crew_name+','
                        this.formRef.current.setFieldsValue({
                            crew_name: str
                        })
                    }
                }
            }
            this.setState({
                visible: true,
                crew_idlist: s
            })
        }

    }

    //更新机组信息
    UPdateList = (id, data) => {
        qr_UpdateCrewlist(id, data).then(res => {
            if (res.status === 200) {
                message.success('修改成功')
                this.setState({
                    visible: false,
                    edit_status: false,
                    selectedRowKeys: []
                })
                this.getCrewList();
                this.formRef.current.resetFields();
            }
        })
    }

    //修改机组信息
    editCrew = (record) => {
        this.getFuelName()
        this.getFuelType()
        this.formRef.current.setFieldsValue({
            capacity: record.capacity,
            crew_name: record.crew_name,
            crew_type: record.crew_type,
            fuel_name: record.fuel_name,
            power_fuel: record.power_fuel,
            source: record.source,
            subdivide: record.subdivide,
        })
        this.setState({
            edit_id:record.id,
            visible: true,
            edit_status: true,
        })
    }
    //关闭编辑弹窗
    onClose = () => {
        this.setState({
            edit_status: false,
            visible: false,
            crew_idlist: [],
            selectedRowKeys: []
        })
        this.formRef.current.setFieldsValue({
            crew_name: ''
        })
    }
    //合并机组
    editSaveForm = values => {
        values['type'] = '填报单位';
        delete values.crew_name
        if (this.state.edit_status) {
            this.UPdateList(this.state.edit_id, values)
        } else {
            values['capacitylist'] = this.state.crew_idlist
            let data = values;
            qr_CreatCrewlist(data).then(res => {
                if (res.status === 200) {
                    message.success('创建成功');
                    this.setState({
                        table_status: true,
                        visible: false,
                        selectedRowKeys: [],
                        crew_idlist:[]
                    });
                    this.getCrewList();
                    this.formRef.current.resetFields();
                }
            })
        }
    }

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };
    //切换机组
    ChangeCrewType = (e) => {
        for (let i = 0; i < this.state.Crew_typeList.length; i++) {
            if (e === this.state.Crew_typeList[i].name) {
                this.setState({
                    subdivide_list: this.state.Crew_typeList[i].subdivide
                })
                this.formRef.current.setFieldsValue({
                    subdivide: ''
                })
            }
        }
    }

    render() {
        const {
            getFuelNameList,
            getFuelTypeList,
            editData_source,
            list,
            selectedRowKeys,
            Crew_typeList,
            subdivide_list
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const columns = [
            {
                title: '机组名称',
                dataIndex: 'crew_name',
                key: 'crew_name',
                fixed: 'left',
                width: '170px'
            },
            {
                title: '机组类型',
                dataIndex: 'crew_type',
                key: 'crew_type',
                width: '100px'
            },
            {
                title: '机组细分类型',
                dataIndex: 'subdivide',
                key: 'subdivide',
            },
            {
                title: '发电燃料类型',
                dataIndex: 'power_fuel',
                key: 'power_fuel',
            },
            {
                title: '燃料名称',
                dataIndex: 'fuel_name',
                key: 'fuel_name',
            },
            {
                title: '装机容量（MW）',
                dataIndex: 'capacity',
                key: 'capacity',
            },
            {
                title: '容量备注',
                dataIndex: 'capacity_remarks',
                key: 'capacity_remarks',
            },
            {
                title: '数据来源',
                dataIndex: 'source',
                key: 'source',
            },
            {
                title: '操作',
                width: '150px',
                fixed: 'right',
                dataIndex: 'action',
                key: 'action',
                render: (txt, record, index) => < div>
                    <Button size="small" type="primary" onClick={this.editCrew.bind(this, record)}
                            style={{marginRight: '10px'}}>编辑</Button>
                    <Popconfirm
                        title="是否删除机组"
                        onConfirm={() => this.deleteReport(record.id)}
                        onCancel={this.cancel}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button type="primary" size="small" danger>删除</Button>
                    </Popconfirm>
                </div>
            },
        ]; //默认
        const edit_container = <Row gutter={16}>{editData_source.map((item, index) => {
            return (
                <Col span={12} key={index}>
                    <Form.Item key={index} label={item.name} name={item.type_name} rules={[
                        {
                            required: item.rules ? true : false,
                            message: '请输入内容',
                        },
                    ]}>
                        {
                            item.type_name === 'crew_type' ?
                                <Select defaultValue={item.value} key={item.value} onChange={this.ChangeCrewType}>{
                                    Crew_typeList.map((Crew_type_item, Crew_type_index) => {
                                        return (
                                            <Select.Option key={Crew_type_index}
                                                           value={Crew_type_item.name}>{Crew_type_item.name}</Select.Option>
                                        )
                                    })
                                }</Select> :
                                item.type_name === 'power_fuel' ?
                                    <Select defaultValue={item.value} key={item.value}>{
                                        getFuelTypeList.map((FuelType_item, FuelType_index) => {
                                            return (
                                                <Select.Option key={FuelType_index}
                                                               value={FuelType_item.name}>{FuelType_item.name}</Select.Option>
                                            )
                                        })
                                    }</Select> : item.type_name === 'fuel_name' ?
                                    <Select defaultValue={item.value} key={item.value}>{
                                        getFuelNameList.map((FuelName_item, FuelName_index) => {
                                            return (
                                                <Select.Option key={FuelName_index}
                                                               value={FuelName_item.name}>{FuelName_item.name}</Select.Option>
                                            )
                                        })
                                    }</Select> : item.type_name === 'subdivide' ?
                                        <Select defaultValue={item.value} key={item.value}>{
                                            subdivide_list.map((subdivide_item, subdivide_index) => {
                                                return (
                                                    <Select.Option key={subdivide_index}
                                                                   value={subdivide_item.subdivide}>{subdivide_item.subdivide}</Select.Option>
                                                )
                                            })
                                        }</Select> :
                                        <Input disabled={item.disabled ? true : false} key={item.value}
                                               data-value={item.value} defaultValue={item.value}/>
                        }

                    </Form.Item>
                </Col>
            )


        })} </Row>//编辑
        const DrawerContainer = <Drawer
            title="编辑机组信息"
            width={720}
            onClose={this.onClose}
            visible={this.state.visible}
            maskClosable={false}
            bodyStyle={{paddingBottom: 80}}
            getContainer={false}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{marginRight: 8}}>
                        取消
                    </Button>
                    <Button type="primary" htmlType='submit'>
                        确认
                    </Button>
                </div>
            }
        >
            {edit_container}
        </Drawer>
        return (
            <Row className="bg-white padding20">
                <Col span={12}><h3>电力行业</h3></Col>
                <Col span={12} style={{marginBottom: '20px', textAlign: "right"}}>
                    <Button type="primary" icon={<PlusOutlined/>} onClick={this.AddTableRow.bind(this)}>合并机组</Button>
                </Col>
                <Col span={24}>
                    <Table rowSelection={rowSelection} columns={columns} dataSource={list} bordered  scroll={{x: 1200}} />
                </Col>
                <Divider/>
                {/*<Col span={12}><h3>化工行业</h3></Col>*/}
                {/*<Col span={12} style={{marginBottom: '20px',textAlign:"right"}}>*/}
                {/*    <Button type="primary" icon={<PlusOutlined/>} onClick={this.AddTableRow.bind(this)}>合并机组</Button>*/}
                {/*</Col>*/}
                {/*<Col span={24}>*/}
                {/*    <Table rowSelection columns={columns} dataSource={list} bordered/>*/}
                {/*</Col>*/}
                <Form initialValues={list} ref={this.formRef} layout='vertical' onFinish={this.editSaveForm.bind(this)}>
                    {DrawerContainer}
                </Form>
            </Row>
        )
    }
}

