import React from 'react'
import * as echarts from 'echarts'
import 'echarts/map/js/china'
import geoJson from 'echarts/map/json/china.json'
import {
    BorderBox1,
    BorderBox8,
    BorderBox13,
    Decoration1,
    ScrollBoard,
    ScrollRankingBoard
} from '@jiaminghi/data-view-react'
import './index.css'

export default class PanoramaIndex extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            myChart1: null,
            myChart2: null,
            myChart3: null,
            myChart4: null,
            myChart5: null,
            myChart6: null,
            topdata: {
                data: [
                    {
                        name: '天然气发电企业',
                        value: 3599504
                    },
                    {
                        name: '燃煤发电企业',
                        value: 2769068
                    },
                    {
                        name: '热电联产企业',
                        value: 3286150
                    },
                    {
                        name: '自备电厂企业',
                        value: 1654089
                    },
                    {
                        name: '甲醇生产企业',
                        value: 109224
                    },
                    {
                        name: '合成氨生产企业',
                        value: 85432
                    },
                    {
                        name: '石油天然气生产企业',
                        value: 1268865
                    },
                    {
                        name:'石油化工企业',
                        value: 2568950,
                    },
                    {
                        name:'乙烯生产企业',
                        value:276500,
                    },
                    {
                        name:'聚丙稀生产企业',
                        value:265086,
                    },
                    {
                        name:'尿素生产企业',
                        value:108765,
                    }
                ],
                carousel: 'page'
            },
            tabledata: {
                header: ['公司', '排放量(tCO2e)'],
                data: [
                    ['天然气发电企业', '3599504',],
                    ['燃煤发电企业', '2769068',],
                    ['热电联产企业', '3286150',],
                    ['自备电厂企业', '1654089',],
                    ['石油化工企业', '2568950',],
                    ['石油天然气企业', '1268865',],
                    ['甲醇生产企业', '109224',],
                    ['乙烯生产企业', '276500',],
                ],
                index: true,
                columnWidth: [40],
                align: ['center']
            }
        }
    }
    componentDidMount() {
        this.initalECharts()
        this.initalECharts1()
        this.initalECharts2()
        this.initalECharts3()
        this.initalECharts4()
        this.initalECharts5()
        const that = this
        window.onresize = function () {
            that.state.myChart1.resize()
            that.state.myChart2.resize()
            that.state.myChart3.resize()
            that.state.myChart4.resize()
            that.state.myChart5.resize()
            that.state.myChart6.resize()
        }
    }

    initalECharts5() {
        this.setState(
            {myChart6: echarts.init(document.getElementById('mainMap3'))},
            () => {
                this.state.myChart6.setOption({
                    title: {
                        show: true,
                        text: '化工企业排放量',
                        x: 'center',
                        textStyle: {
                            //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            fontSize: 14,
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            color: '#01c4f7'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        textStyle: {
                            fontSize: 12,
                            color: '#ffffff'
                        },
                        top: 20,
                        itemWidth: 20, // 设置宽度

                        itemHeight: 12, // 设置高度

                        itemGap: 10 // 设置间距
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月'],
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#07234d']
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#c3dbff', //更改坐标轴文字颜色
                                fontSize: 12 //更改坐标轴文字大小
                            }
                        }
                    },
                    yAxis: {
                        // name: '单位：tCO2e',
                        // nameTextStyle: {
                        //     color: "#c3dbff",
                        //     fontSize: 12,
                        // },
                        type: 'value',
                        boundaryGap: [0, 0.01],
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#07234d']
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#c3dbff', //更改坐标轴文字颜色
                                fontSize: 12 //更改坐标轴文字大小
                            }
                        }
                    },
                    series: [
                        {
                            name: '排放量（tCO2e）',
                            type: 'bar',
                            data: [387953, 368432,468542, 484528, 443286, 453210],
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {offset: 0, color: '#9408fc'},
                                    {offset: 1, color: '#05aed3'}
                                ])
                            }
                        },
                    ]
                })
            }
        )
    }

    initalECharts4() {
        this.setState(
            {myChart5: echarts.init(document.getElementById('mainMap2'))},
            () => {
                this.state.myChart5.setOption({
                    title: {
                        show: true,
                        text: '电力企业排放量',
                        x: 'center',
                        textStyle: {
                            //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            fontSize: 14,
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            color: '#01c4f7'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        textStyle: {
                            fontSize: 12,
                            color: '#ffffff'
                        },
                        top: 20,
                        itemWidth: 20, // 设置宽度

                        itemHeight: 12, // 设置高度

                        itemGap: 10 // 设置间距
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: ['1月', '2月', '3月', '4月', '5月', '6月'],
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#07234d']
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#c3dbff', //更改坐标轴文字颜色
                                fontSize: 12 //更改坐标轴文字大小
                            }
                        }
                    },
                    yAxis: {
                        // name: '单位：tCO2e',
                        // nameTextStyle: {
                        //     color: "#c3dbff",
                        //     fontSize: 12,
                        // },
                        type: 'value',
                        boundaryGap: [0, 0.01],
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#07234d']
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#c3dbff', //更改坐标轴文字颜色
                                fontSize: 12 //更改坐标轴文字大小
                            }
                        }
                    },
                    series: [
                        {
                            name: '排放量（tCO2e）',
                            type: 'bar',
                            data: [1023025,989642, 878543, 854229, 832786, 825498],
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {offset: 0, color: '#13b985'},
                                    {offset: 1, color: '#dc9b18'}
                                ])
                            }
                        }
                    ]
                })
            }
        )
    }

    initalECharts3() {
        this.setState(
            {myChart4: echarts.init(document.getElementById('countyMap'))},
            () => {
                this.state.myChart4.setOption({
                    color: ['#ff832e', '#37cbff', '#b3e269'],
                    legend: {
                        top: 30,
                        data: ['本年度配额', '上年度结转配额', 'CCER'],
                        textStyle: {
                            fontSize: 12,
                            color: '#ffffff'
                        },
                        icon: 'circle',
                        itemWidth: 10, // 设置宽度
                        itemHeight: 10, // 设置高度
                        itemGap: 10 // 设置间距
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#07234d']
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#c3dbff', //更改坐标轴文字颜色
                                fontSize: 12 //更改坐标轴文字大小
                            },
                            interval:0,
                            rotate:20//角度顺时针计算的
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: ['内蒙公司', '山西公司', '北京公司', '陕西公司', '湖北公司'],
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#c3dbff', //更改坐标轴文字颜色
                                fontSize: 12 //更改坐标轴文字大小
                            }
                        },
                    },
                    series: [
                        {
                            name: '本年度配额',
                            type: 'bar',
                            barWidth: '60%',
                            stack: '总量',
                            label: {
                                show: false,
                                position: 'insideRight'
                            },
                            data:  [1653289, 3086540, 3599504,4553682,1638695]

                        },
                        {
                            name: '上年度结转配额',
                            type: 'bar',
                            stack: '总量',
                            label: {
                                show: false,
                                position: 'insideRight'
                            },
                            data: [135432, 0, 0, 220987, 270085]
                        },
                        {
                            name: 'CCER',
                            type: 'bar',
                            stack: '总量',
                            label: {
                                show: false,
                                position: 'insideRight'
                            },
                            data: [285798, 108766, 55438,85432, 118771]
                        },
                    ]
                })
            }
        )
    }

    initalECharts2() {
        this.setState(
            {myChart3: echarts.init(document.getElementById('cityMap'))},
            () => {
                this.state.myChart3.setOption({
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 1,
                        y2: 0,
                        colorStops: [
                            {
                                offset: 0,
                                color: '#d000d0' // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#7006d9' // 100% 处的颜色
                            }
                        ],
                        globalCoord: false
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    color: ['#07234d']
                                }
                            },
                            axisLabel: {
                                show: true,
                                textStyle: {
                                    color: '#c3dbff', //更改坐标轴文字颜色
                                    fontSize: 12 //更改坐标轴文字大小
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            // name: '单位：tCO2e',
                            // nameTextStyle: {
                            //     color: "#c3dbff",
                            //     fontSize: 12,
                            // },
                            type: 'category',
                            data: ['其他','风电', '光电', '碳汇'],
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLabel: {
                                show: true,
                                textStyle: {
                                    color: '#c3dbff', //更改坐标轴文字颜色
                                    fontSize: 12 //更改坐标轴文字大小
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: 'CCER量',
                            type: 'bar',
                            barWidth: '60%',
                            data: [ 111063, 205834, 228657, 108651]

                        }
                    ]
                })
            }
        )
    }

    initalECharts1() {
        this.setState(
            {myChart2: echarts.init(document.getElementById('provinceMap'))},
            () => {
                this.state.myChart2.setOption({
                    color: ['#9702fe', '#ff893b', '#37cbff', '#d90051', '#b2e269'],
                    // title: {
                    //     text: '交通方式',
                    //     top: '48%',
                    //     textAlign: "center",
                    //     left: "49%",
                    //     textStyle: {
                    //         color: '#fff',
                    //         fontSize: 12,
                    //         fontWeight: '400'
                    //     }
                    // },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b}: {c} ({d}%)'
                    },
                    legend: {
                        orient: 'vertical',
                        top: 30,
                        right: '2%',
                        data: ['本年度发放配额量', '上一年度结转配额量', 'CCER量'],
                        textStyle: {
                            fontSize: 12,
                            color: '#ffffff'
                        },
                        icon: 'circle',
                        itemWidth: 10, // 设置宽度

                        itemHeight: 10, // 设置高度

                        itemGap: 10 // 设置间距
                    },
                    series: [
                        {
                            name: '访问来源',
                            type: 'pie',
                            radius: ['50%', '70%'],
                            center: ['35%', '50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            // emphasis: {
                            //     label: {
                            //         show: true,
                            //         fontSize: '3',
                            //         fontWeight: 'bold'
                            //     }
                            // },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: 12893015, name: '本年度发放配额量'},
                                {value: 626504, name: '上一年度结转配额量'},
                                {value: 654205, name: 'CCER量'},
                            ]
                        }
                    ]
                })
            }
        )
    }

    initalECharts() {
        echarts.registerMap('zhongguo', geoJson)
        const flyGeo = {
            洛阳: [112.460299, 34.62677],
            西安: [108.946466, 34.347269],
            兰州: [103.84044, 36.067321],
            乌鲁木齐: [87.62444, 43.830763],
            包头: [109.846544, 40.662929],
            西宁: [101.78443, 36.623393],
            银川: [106.258602, 38.487834],
            成都: [104.081534, 30.655822],
            重庆: [106.558434, 29.568996],
            拉萨: [91.120789, 29.65005],
            昆明: [102.852448, 24.873998],
            贵阳: [106.636577, 26.653325],
            太原: [112.534919, 37.873219],
            武汉: [114.311582, 30.598467],
            长沙: [112.945473, 28.234889],
            南昌: [115.864589, 28.689455],
            合肥: [117.233443, 31.826578],
            杭州: [120.215503, 30.253087],
            广州: [113.271431, 23.135336],
            北京: [116.413384, 39.910925],
            天津: [117.209523, 39.093668]
        }

        //飞线数据
        const flyVal = [
            [{name: '洛阳'}, {name: '洛阳', value: 100}],
            [{name: '洛阳'}, {name: '西安', value: 35}],
            [{name: '洛阳'}, {name: '兰州', value: 25}],
            [{name: '洛阳'}, {name: '乌鲁木齐', value: 55}],
            [{name: '洛阳'}, {name: '包头', value: 60}],
            [{name: '洛阳'}, {name: '西宁', value: 45}],
            [{name: '洛阳'}, {name: '银川', value: 35}],
            [{name: '洛阳'}, {name: '成都', value: 35}],
            [{name: '洛阳'}, {name: '重庆', value: 40}],
            [{name: '洛阳'}, {name: '拉萨', value: 205}],
            [{name: '洛阳'}, {name: '昆明', value: 50}],
            [{name: '洛阳'}, {name: '贵阳', value: 55}],
            [{name: '洛阳'}, {name: '太原', value: 60}],
            [{name: '洛阳'}, {name: '武汉', value: 65}],
            [{name: '洛阳'}, {name: '长沙', value: 70}],
            [{name: '洛阳'}, {name: '南昌', value: 75}],
            [{name: '洛阳'}, {name: '合肥', value: 80}],
            [{name: '洛阳'}, {name: '杭州', value: 85}],
            [{name: '洛阳'}, {name: '广州', value: 90}],
            [{name: '洛阳'}, {name: '北京', value: 95}],
            [{name: '洛阳'}, {name: '天津', value: 60}]
        ]
        //数据转换，转换后格式：[{fromName:'cityName', toName:'cityName', coords:[[lng, lat], [lng, lat]]}, {...}]
        //数据转换，转换后格式：[{fromName:'cityName', toName:'cityName', coords:[[lng, lat], [lng, lat]]}, {...}]
        const convertFlyData = function (data) {
            let res = []
            for (let i = 0; i < data.length; i++) {
                let dataItem = data[i]
                let toCoord = flyGeo[dataItem[0].name]
                let fromCoord = flyGeo[dataItem[1].name]
                if (fromCoord && toCoord) {
                    res.push({
                        fromName: dataItem[1].name,
                        toName: dataItem[0].name,
                        coords: [fromCoord, toCoord]
                    })
                }
            }
            return res
        }
        //报表配置
        const originName = '浙江'
        const flySeries = []
        ;[[originName, flyVal]].forEach(function (item, i) {
            flySeries.push(
                {
                    name: item[0],
                    type: 'lines',
                    zlevel: 1,
                    symbol: ['none', 'none'],
                    symbolSize: 0,
                    effect: {
                        //特效线配置
                        show: true,
                        period: 5, //特效动画时间，单位s
                        trailLength: 0.1, //特效尾迹的长度，从0到1
                        symbol: 'arrow',
                        symbolSize: 5
                    },
                    lineStyle: {
                        normal: {
                            color: '#f19000',
                            width: 1,
                            opacity: 0.6,
                            curveness: 0.2 //线的平滑度
                        }
                    },
                    data: convertFlyData(item[1])
                },
                {
                    name: item[0],
                    type: 'effectScatter',
                    coordinateSystem: 'geo',
                    zlevel: 2,
                    rippleEffect: {
                        //涟漪特效
                        period: 5, //特效动画时长
                        scale: 4, //波纹的最大缩放比例
                        brushType: 'stroke' //波纹的绘制方式：stroke | fill
                    },
                    label: {
                        normal: {
                            show: false,
                            position: 'right',
                            formatter: '{b}'
                        }
                    },
                    symbol: 'circle',
                    symbolSize: function (val) {
                        //根据某项数据值设置符号大小
                        return val[2] / 10
                    },
                    itemStyle: {
                        normal: {
                            color: '#f19000'
                        }
                    },
                    data: item[1].map(function (dataItem) {
                        return {
                            name: dataItem[1].name,
                            value: flyGeo[dataItem[1].name].concat([dataItem[1].value])
                        }
                    })
                },
                {
                    //与上层的点叠加
                    name: item[0],
                    type: 'scatter',
                    coordinateSystem: 'geo',
                    zlevel: 3,
                    symbol: 'circle',
                    symbolSize: function (val) {
                        //根据某项数据值设置符号大小
                        return val[2] / 15
                    },
                    itemStyle: {
                        normal: {
                            color: '#f00'
                        }
                    },
                    data: item[1].map(function (dataItem) {
                        return {
                            name: dataItem[1].name,
                            value: flyGeo[dataItem[1].name].concat([dataItem[1].value])
                        }
                    })
                }
            )
        })

        this.setState(
            {myChart1: echarts.init(document.getElementById('mainMap'))},
            () => {
                this.state.myChart1.setOption({
                    // tooltip: {
                    //     trigger: 'item'
                    // },
                    visualMap: {
                        // orient: 'horizontal',
                        min: 0,
                        left: '20%',
                        max: 10000,
                        text: ['高', '低'], // 文本，默认为数值文本
                        splitNumber: 0,
                        color: ['#0054bb', '#85ADDE'],
                        textStyle: {
                            color: '#fff'
                        }
                    },

                    series: [
                        {
                            name: '公司排放量',
                            type: 'map',
                            mapType: 'china',
                            mapLocation: {
                                x: 'center',
                                y:'60px'
                            },
                            // selectedMode: 'multiple',
                            itemStyle: {
                                normal: {label: {show: true, color: '#fff'}, borderWidth: 0}
                            },
                            data: [
                                {name: '西藏', value: 700},
                                {name: '青海', value: 1670.44},
                                {name: '宁夏', value: 2102.21},
                                {name: '海南', value: 2522.66},
                                {name: '甘肃', value: 5020.37},
                                {name: '贵州', value: 5701.84},
                                {name: '新疆', value: 6610.05},
                                {name: '云南', value: 22},
                                {name: '重庆', value: 500},
                                {name: '吉林', value: 1000},
                                {name: '山西', value: 3195306},
                                {name: '天津', value: 4000},
                                {name: '江西', value: 9000},
                                {name: '广西', value: 689},
                                {name: '陕西', value: 4860101},
                                {name: '黑龙江', value: 12582},
                                {name: '内蒙古', value: 2074519},
                                {name: '安徽', value: 22},
                                {name: '北京', value: 3654942},
                                {name: '福建', value: 1223},
                                {name: '上海', value: 19195.69},
                                {name: '湖北', value: 2027551},
                                {name: '湖南', value: 8872},
                                {name: '四川', value: 21026.68},
                                {name: '辽宁', value: 22226.7},
                                {name: '河北', value: 24515.76},
                                {name: '河南', value: 26931.03},
                                {name: '浙江', value: 32318.85},
                                {name: '山东', value: 45361.85},
                                {name: '江苏', value: 49110.27},
                                {name: '广东', value: 53210.28},
                                {name: '台湾', value: 53210.28},
                                {name: '南海诸岛', value: 53210.28}
                            ]
                        }
                    ]
                })
            }
        )
    }

    goIndex(){
        this.props.history.replace('/user')
    }
    render() {
        const {topdata, tabledata} = this.state
        return (
            <div className="data">
                <header className="header_main">
                    <div className="left_bg"></div>
                    <div className="right_bg"></div>
                    <h3>集团碳管理平台</h3>
                    <h4 onClick={this.goIndex.bind(this)}>点击进入系统</h4>
                </header>
                <div className="wrapper">
                    <div className="container-fluid">
                        <div className="row fill-h" style={{display: 'flex'}}>
                            <div className="col-lg-3 fill-h" style={{width: '25%'}}>
                                <div
                                    className="xpanel-wrapper xpanel-wrapper-5"
                                    style={{position: 'relative'}}
                                >
                                    <div className="content_title">配额排名（单位：吨）</div>
                                    <BorderBox1>
                                        <div className="xpanel">
                                            <div className="fill-h" id="mainMap1">
                                                {/* <Loading style={{color:'#fff'}}>加载中...</Loading> */}
                                                <ScrollRankingBoard config={topdata}/>
                                            </div>
                                        </div>
                                    </BorderBox1>
                                </div>
                                <div className="xpanel-wrapper xpanel-wrapper-4">
                                    <BorderBox13>
                                        <div className="xpanel">
                                            <div className="fill-h" id="worldMap">
                                                <ScrollBoard config={tabledata}/>
                                            </div>
                                        </div>
                                    </BorderBox13>
                                </div>
                            </div>
                            <div className="col-lg-6 fill-h" style={{width: '50%'}}>
                                <div className="xpanel-wrapper xpanel-wrapper-5">
                                    <div
                                        className="xpanel"
                                        style={{
                                            position: 'relative'
                                        }}
                                    >
                                        <div className="map_bg"></div>
                                        <div className="circle_allow"></div>
                                        <div className="circle_bg"></div>

                                        <div style={{
                                            width: '100%',
                                            position: 'absolute',
                                            top: 10,
                                            display: 'flex',
                                            left: '50%',
                                            justifyContent: 'center',
                                            color: '#fff',
                                            alignItems: 'center',
                                            transform: 'translateX(-50%)'
                                        }}>
                                            <p>碳排放总量（吨）：</p>
                                            <div className="databg">1</div>
                                            <div className="databg">5</div>
                                            <div className="databg">9</div>
                                            <div className="databg">9</div>
                                            <div className="databg">1</div>
                                            <div className="databg">6</div>
                                            <div className="databg">3</div>
                                            <div className="databg">3</div>
                                        </div>
                                        <div
                                            style={{
                                                height: 60,
                                                width: 200,
                                                position: 'absolute',
                                                top: 20,
                                                right: 20
                                            }}
                                        >
                                            <Decoration1 style={{width: '100%', height: '100%'}}/>
                                        </div>

                                        <div className="fill-h" id="mainMap"></div>
                                    </div>
                                </div>
                                <div
                                    className="xpanel-wrapper xpanel-wrapper-4"
                                    style={{display: 'flex'}}
                                >
                                    <div
                                        style={{
                                            width: '50%',
                                            paddingRight: 8,
                                            position: 'relative'
                                        }}
                                    >
                                        <BorderBox8>
                                            <div className="xpanel">
                                                <div className="fill-h" id="mainMap2"></div>
                                            </div>
                                        </BorderBox8>
                                    </div>
                                    <div style={{width: '50%', paddingLeft: 8}}>
                                        <BorderBox8>
                                            <div className="xpanel">
                                                <div className="fill-h" id="mainMap3"></div>
                                            </div>
                                        </BorderBox8>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 fill-h" style={{width: '25%'}}>
                                <div
                                    className="xpanel-wrapper xpanel-wrapper-6"
                                    style={{position: 'relative'}}
                                >
                                    <div className="content_title">配额组成（单位：吨）</div>
                                    <BorderBox1>
                                        <div className="xpanel">
                                            <div className="fill-h" id="provinceMap"></div>
                                        </div>
                                    </BorderBox1>
                                </div>
                                <div
                                    className="xpanel-wrapper xpanel-wrapper-6"
                                    style={{position: 'relative'}}
                                >
                                    <div className="content_title">CCER组成（单位：吨）</div>
                                    <BorderBox1>
                                        <div className="xpanel">
                                            <div className="fill-h" id="cityMap"></div>
                                        </div>
                                    </BorderBox1>
                                </div>
                                <div
                                    className="xpanel-wrapper xpanel-wrapper-4"
                                    style={{position: 'relative'}}
                                >
                                    <div className="content_title">公司配额组成（单位：吨）</div>
                                    <BorderBox1>
                                        <div className="xpanel">
                                            <div className="fill-h" id="countyMap"></div>
                                        </div>
                                    </BorderBox1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



