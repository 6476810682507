import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import {Layout, Menu, Breadcrumb, Dropdown, message, Button, Tag} from 'antd';
import routes from '../../utils/router'
import {getBreadItem, filterRoutes, recursionRouterTwo} from '../../utils'
import {Link, Redirect, Route, Switch} from "react-router-dom";
import NotFound from "./NotFound/NotFound";
import {DownOutlined, PieChartOutlined, UserOutlined} from "@ant-design/icons";
import {getMenu} from "../../api/api";
import cookie from 'react-cookies'

const {SubMenu} = Menu;
const {Header, Content, Sider} = Layout;

function handleMenuClick(e) {
    message.info('退出登录');
}

const menu = (
    <Menu onClick={handleMenuClick}>
        <Menu.Item key="2">
            <Link to='/'>退出登录</Link>
        </Menu.Item>
    </Menu>
);
export default class Index extends React.Component {
    state = {
        current: '/user',
    }
    goto = ({key}) => {
        this.setState({
            current: key
        })
        this.props.history.push(key)
    }

    componentWillMount() {
        let that = this;
        getMenu().then(res => {
            if (res.status === 200) {
                this.setState({
                    dynamic_routes: res.data.detail
                })
                const menuTreeNode = this.renderMenu(that.state.dynamic_routes);
                const {pathname} = this.props.location;
                this.setState({
                    menuTreeNode,
                    collapsed: false,
                    list: [123],
                    current: pathname.replace(this.props.match.url, ''),
                    role_name: cookie.load('roles')
                })
                this.setState({
                    menuTreeNode_data: menuTreeNode
                })
            }
        })
    }
    onCollapse = collapsed => {
        this.setState({collapsed});
    };
    // 递归实现----菜单渲染
    renderMenu = (data) => {
        return data.map((item) => {
            if (item.hidden) return false
            if (item.children&&item.children.length>0) {
                return <SubMenu key={item.path} title={item.name} icon={<PieChartOutlined/>}
                >
                    {this.renderMenu(item.children)}
                </SubMenu>
            }
            return <Menu.Item key={item.path} icon={<PieChartOutlined/>}> <Link to={item.path}>{item.name}</Link>
            </Menu.Item>
        })
    }

    render() {
        //默认进入子组件index
        if (this.props.location.pathname === '/user') {
            return (
                <Redirect path="/user" exact={true} to={{pathname: '/user/index'}}/>
            )
        }
        //const { current } = this.state
        const path = this.props.location.pathname
        const defaultOpenKeys = filterRoutes(path)
        const breadList = recursionRouterTwo(defaultOpenKeys, routes)
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Header className="header">
                    <h3 className="logoTitle">碳排放管理系统</h3>
                    <div className="loginUser">
                        {cookie.load('roles') === '集团用户' ?
                            <Link to='/user/panorama'><Tag color="blue">查看数据监测</Tag></Link> : ''}
                        <Dropdown overlay={menu}>
                            <Button>
                                <UserOutlined/> {cookie.load('roles') === '集团用户' ? 'admin' : 'user'} <DownOutlined/>
                            </Button>
                        </Dropdown>
                    </div>
                </Header>
                <Layout>
                    <Sider width={200} className="site-layout-background" collapsible collapsed={this.state.collapsed}
                           onCollapse={this.onCollapse}>
                        <Menu
                            theme="dark"
                            mode="inline"
                            style={{height: '100%', borderRight: 0}}
                        >
                            {this.state.menuTreeNode_data}
                            {/*    菜单渲染位置*/}
                        </Menu>
                    </Sider>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <Breadcrumb style={{margin: '16px 0'}}>
                            {
                                getBreadItem(breadList)
                            }
                        </Breadcrumb>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 0,
                                margin: 0,
                                minHeight: 280,
                                background: 'none'
                            }}
                        >
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        component={route.component}
                                    />
                                ))}
                                <Route component={NotFound}/>
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }

}
