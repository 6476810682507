import React from "react";
import {Button, Col, message, Popconfirm, Row, Table, Form, Input, Select, Drawer, InputNumber} from "antd";
import 'antd/dist/antd.css';
import {PlusOutlined} from "@ant-design/icons";
import {
    qr_getCrewlist,
    qr_deleteCrewlist,
    qr_getfuelname,
    qr_getfueltype,
    qr_CreatCrewlist,
    qr_UpdateCrewlist,
    CrewType
} from '../../../api/api'
import cookie from 'react-cookies'
export default class CrewInfo extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            subdivide_list: [],
            Crew_typeList: [],
            loading: false,
            selectedRowKeys: [],
            visible: false,
            edit_status: false,//编辑图层状态按钮
            table_status: true,//表格状态编辑or展示 true代表展示 false代表新增
            crewList: [],
            getFuelNameList: [],//燃料名称arr
            getFuelTypeList: [],//燃料类型arr
            editData_source: [ //编辑表格内容
                {
                    type_name: 'crew_name', //name值 机组名称
                    name: '机组名称', //label值
                    value: '',
                    type: 'text',
                    rules: true
                },
                {
                    type_name: 'crew_type', //name值 机组类型
                    name: '机组类型', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'subdivide', //name值 机组类型
                    name: '机组细分类型', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'power_fuel', //name值 发电燃料类型
                    name: '发电燃料类型', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'fuel_name', //name值 燃料
                    name: '燃料名称', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'capacity', //name值 装机容量
                    name: '装机容量（MW）', //label值
                    value: '',
                    type: 'number',
                    rules: true
                },
                {
                    type_name: 'steam_cooling_mode', //name值 汽轮机排气冷却方式
                    name: '汽轮机排气冷却方式', //label值
                    value: '',
                    type: 'select',
                    rules: true
                },
                {
                    type_name: 'source', //name值 数据来源
                    name: '数据来源', //label值
                    value: '',
                    type: 'text',
                    rules: false
                },
                {
                    //name值 锅炉
                    name: '机组信息（锅炉）', //label值
                    children: [
                        {
                            type_name: 'boiler_name', //name值 锅炉名称
                            name: '锅炉名称', //label值
                            value: '示例：1#锅炉',
                            type: 'text'
                        },
                        {
                            type_name: 'boiler_type', //name值 锅炉类型
                            name: '锅炉类型', //label值
                            value: '示例：煤粉炉',
                            type: 'text'
                        },
                        {
                            type_name: 'boiler_num', //name值 锅炉编号
                            name: '锅炉编号', //label值
                            value: '示例：MF001',
                            type: 'text'
                        },
                        {
                            type_name: 'boiler_model', //name值 锅炉型号
                            name: '锅炉型号', //label值
                            value: '示例：HG-2030/17.5-YM',
                            type: 'text'
                        },
                        {
                            type_name: 'boiler_product', //name值 生产能力
                            name: '生产能力', //label值
                            value: '示例：2030 t/h',
                            type: 'text'
                        },
                    ]
                },
                {
                    //name值 汽轮机
                    name: '机组信息（汽轮机）', //label值
                    children: [
                        {
                            type_name: 'steam_name', //name值 汽轮机名称
                            name: '汽轮机名称', //label值
                            value: '示例：1#',
                            type: 'text'
                        },
                        {
                            type_name: 'steam_type', //name值 汽轮机类型
                            name: '汽轮机类型', //label值
                            value: '示例：抽凝式',
                            type: 'text'
                        },
                        {
                            type_name: 'steam_num', //name值 汽轮机编号
                            name: '汽轮机编号', //label值
                            value: '示例：MF002',
                            type: 'text'
                        },
                        {
                            type_name: 'steam_model', //name值 汽轮机型号
                            name: '汽轮机型号', //label值
                            value: '示例：N630-16.7/538/538',
                            type: 'text'
                        },
                        {
                            type_name: 'steam_param', //name值 压力参数
                            name: '压力参数', //label值
                            value: '示例：中压',
                            type: 'text'
                        },
                        {
                            type_name: 'steam_rated', //name值 额定功率
                            name: '额定功率', //label值
                            value: '示例：630',
                            type: 'number'
                        },
                    ]
                },
                {
                    //name值 发电机
                    name: '机组信息（发电机）', //label值
                    children: [
                        {
                            type_name: 'alternator_name', //name值 发电机名称
                            name: '发电机名称', //label值
                            value: '示例：1#',
                            type: 'text'
                        },
                        {
                            type_name: 'alternator_num', //name值 发电机编号
                            name: '发电机编号', //label值
                            value: '示例：MF003',
                            type: 'text'
                        },
                        {
                            type_name: 'alternator_model', //name值 发电机型号
                            name: '发电机型号', //label值
                            value: '示例：QFSN-630-2',
                            type: 'text'
                        },
                        {
                            type_name: 'alternator_rated', //name值 额定功率
                            name: '额定功率', //label值
                            value: '示例：630',
                            type: 'number'
                        },
                    ]
                },
            ],
        }
    }

    componentDidMount() {
        this.getCrewList();
        this.getCrewType()
    }

    QXlist = () => {
        this.setState({
            table_status: true
        })
    }
    //获取机组信息
    getCrewList = () => {
        qr_getCrewlist('机组信息').then(res => {
            if (res.status === 200) {
                if (res.data.results.length > 0) {
                    this.setState({
                        list: res.data.results
                    })
                    cookie.save('crew_status', 'true')
                }
            }
        })
    }
    //获取机组类型细分
    getCrewType = () => {
        CrewType().then(res => {
            console.log(res)
            if (res.status === 200) {
                this.setState({
                    Crew_typeList: res.data,
                    subdivide_list: res.data[0].subdivide
                })
            }
        })
    }
    //删除机组
    deleteReport = (e) => {
        console.log(e)
        qr_deleteCrewlist(e).then(res => {
            if (res.status === 200) {
                message.success('删除成功')
                this.getCrewList()
            }
        })
    }

    //取消删除
    cancel(e) {
        message.error('取消删除');
    }

    //获取燃料名称
    getFuelName = () => {
        qr_getfuelname().then(res => {
            if (res.status === 200) {
                this.setState({
                    getFuelNameList: res.data
                })
            }
        })
    }
    //发电燃料类型
    getFuelType = () => {
        qr_getfueltype().then(res => {
            if (res.status === 200) {
                this.setState({
                    getFuelTypeList: res.data
                })
            }
        })
    }

    //新增表格行
    AddTableRow() {
        this.getFuelName()
        this.getFuelType()
        this.setState({
            visible: true,
        })
    }

    //更新机组信息
    UPdateList = (id, data) => {
        qr_UpdateCrewlist(id, data).then(res => {
            if (res.status === 200) {
                message.success('修改成功')
                this.setState({
                    visible: false,
                    edit_status: false,
                })
                this.getCrewList();
                this.formRef.current.resetFields();
            }
        })
    }

    //修改机组信息
    editCrew = (record) => {
        console.log(record)
        this.formRef.current.setFieldsValue({
            crew_type: record.crew_type,
            crew_name: record.crew_name,
            alternator_model: record.alternator_model,
            alternator_name: record.alternator_name,
            alternator_num: record.alternator_num,
            alternator_rated: record.alternator_rated,
            boiler_model: record.boiler_model,
            boiler_name: record.boiler_name,
            boiler_num: record.boiler_num,
            boiler_product: record.boiler_product,
            boiler_type: record.boiler_type,
            capacity: record.capacity,
            fuel_name: record.fuel_name,
            power_fuel: record.power_fuel,
            source: record.source,
            steam_cooling_mode: record.steam_cooling_mode,
            steam_model: record.steam_model,
            steam_name: record.steam_name,
            steam_num: record.steam_num,
            steam_param: record.steam_param,
            steam_rated: record.steam_rated,
            steam_type: record.steam_type,
            subdivide: record.subdivide,
        })
        this.setState({
            visible: true,
            edit_status: true,
            edit_id: record.id
        })
    }
    //关闭编辑弹窗
    onClose = () => {
        this.setState({
            edit_status: false,
            visible: false,
        })
        this.formRef.current.resetFields()
    }

    //保存机组信息
    editSaveForm = values => {
        cookie.load('token')
        values['type'] = '机组信息';
        if (this.state.edit_status) {
            this.UPdateList(this.state.edit_id, values)
        } else {
            let data = values;
            qr_CreatCrewlist(data).then(res => {
                if (res.status === 200) {
                    message.success('创建成功');
                    this.setState({
                        table_status: true,
                        visible: false
                    });
                    this.getCrewList();
                    this.formRef.current.resetFields();
                }
            })
        }
    }

    //切换机组
    ChangeCrewType = (e) => {
        console.log(e)
        console.log(this.state.Crew_typeList)
        for (let i = 0; i < this.state.Crew_typeList.length; i++) {
            if (e === this.state.Crew_typeList[i].name) {
                this.setState({
                    subdivide_list: this.state.Crew_typeList[i].subdivide
                })
                this.formRef.current.setFieldsValue({
                    subdivide: ''
                })
            }
        }
    }

    render() {
        const {
            getFuelNameList,
            getFuelTypeList,
            editData_source,
            list,
            Crew_typeList,
            subdivide_list
        } = this.state;
        const columns = [
            {
                title: '机组名称',
                dataIndex: 'crew_name',
                key: 'crew_name',
                fixed: 'left'
            },
            {
                title: '机组类型',
                dataIndex: 'crew_type',
                key: 'crew_type',
            },
            {
                title: '机组细分类型',
                dataIndex: 'subdivide',
                key: 'subdivide',
            },
            {
                title: '发电燃料类型',
                dataIndex: 'power_fuel',
                key: 'power_fuel',
            },
            {
                title: '燃料名称',
                dataIndex: 'fuel_name',
                key: 'fuel_name',
            },
            {
                title: '装机容量（MW）',
                dataIndex: 'capacity',
                key: 'capacity',
            },
            {
                title: '数据来源',
                dataIndex: 'source',
                key: 'source',
            },
            {
                title: '操作',
                width: '150px',
                fixed: 'right',
                dataIndex: 'action',
                key: 'action',
                render: (txt, record, index) => < div>
                    <Button size="small" type="primary" onClick={this.editCrew.bind(this, record)}
                            style={{marginRight: '10px'}}>编辑</Button>
                    <Popconfirm
                        title="是否删除机组"
                        onConfirm={() => this.deleteReport(record.id)}
                        onCancel={this.cancel}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button type="primary" size="small" danger>删除</Button>
                    </Popconfirm>
                </div>
            },
        ]; //默认
        const edit_container = <Row gutter={16}>{editData_source.map((item, index) => {
            if (item.children) {
                var ch = item.children.map((i, i_index) => {
                    return (
                        <Col span={24} key={i_index}>
                            <Form.Item key={i_index} label={i.name} name={i.type_name}>
                                <Input key={i_index} placeholder={i.value}/>
                            </Form.Item>
                        </Col>
                    )
                })
            }
            if (!item.type_name) {
                return (
                    <Col span={24} key={index}>
                        <Form.Item key={index} label={item.name} name={item.type_name}>
                            {ch}
                        </Form.Item>
                    </Col>
                )
            }
            return (
                <Col span={12} key={index}>
                    <Form.Item key={index} label={item.name} name={item.type_name} rules={[
                        {
                            required: item.rules ? true : false,
                            message: '请输入内容',
                        },
                    ]}>
                        {
                            item.type_name === 'crew_type' ?
                                <Select defaultValue={item.value} key={item.value} onChange={this.ChangeCrewType}>{
                                    Crew_typeList.map((Crew_type_item, Crew_type_index) => {
                                        return (
                                            <Select.Option key={Crew_type_index}
                                                           value={Crew_type_item.name}>{Crew_type_item.name}</Select.Option>
                                        )
                                    })
                                }</Select> :
                                item.type_name === 'power_fuel' ?
                                    <Select defaultValue={item.value} key={item.value}>{
                                        getFuelTypeList.map((FuelType_item, FuelType_index) => {
                                            return (
                                                <Select.Option key={FuelType_index}
                                                               value={FuelType_item.name}>{FuelType_item.name}</Select.Option>
                                            )
                                        })
                                    }</Select> : item.type_name === 'fuel_name' ?
                                    <Select defaultValue={item.value} key={item.value}>{
                                        getFuelNameList.map((FuelName_item, FuelName_index) => {
                                            return (
                                                <Select.Option key={FuelName_index}
                                                               value={FuelName_item.name}>{FuelName_item.name}</Select.Option>
                                            )
                                        })
                                    }</Select> : item.type_name === 'subdivide' ?
                                        <Select defaultValue={item.value} key={item.value}>{
                                            subdivide_list.map((subdivide_item, subdivide_index) => {
                                                return (
                                                    <Select.Option key={subdivide_index}
                                                                   value={subdivide_item.subdivide}>{subdivide_item.subdivide}</Select.Option>
                                                )
                                            })
                                        }</Select> : item.type_name === 'steam_cooling_mode' ?
                                            <Select defaultValue={item.value} key={item.value}>
                                                <Select.Option value='水冷（开式）'>水冷（开式）</Select.Option>
                                                <Select.Option value='水冷（开式）'>水冷（闭式）</Select.Option>
                                                <Select.Option value='空冷（直接）'>空冷（直接）</Select.Option>
                                                <Select.Option value='空冷（间接）'>空冷（间接）</Select.Option>
                                            </Select>
                                            : item.type_name === 'crew_name' || item.type_name === 'source' ?
                                                <Input/> :
                                                <InputNumber type={item.type} key={item.value} data-value={item.value}
                                                             defaultValue={item.value}/>
                        }

                    </Form.Item>
                </Col>
            )


        })} </Row>//编辑
        const DrawerContainer = <Drawer
            title="编辑机组信息"
            width={720}
            onClose={this.onClose}
            visible={this.state.visible}
            maskClosable={false}
            bodyStyle={{paddingBottom: 80}}
            getContainer={false}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{marginRight: 8}}>
                        取消
                    </Button>
                    <Button type="primary" htmlType='submit'>
                        确认
                    </Button>
                </div>
            }
        >
            {edit_container}
        </Drawer>
        return (
            <Row className="bg-white padding20">
                <Col span={12}><h3>电力行业</h3></Col>
                <Col span={12} style={{marginBottom: '20px', textAlign: "right"}}>
                    <Button type="primary" icon={<PlusOutlined/>} onClick={this.AddTableRow.bind(this)}>新增</Button>
                </Col>
                <Col span={24}>
                    <Table columns={columns} dataSource={list} bordered/>
                </Col>
                {/*<Divider/>*/}
                {/*<Col span={12}><h3>化工行业</h3></Col>*/}
                {/*<Col span={12} style={{marginBottom: '20px', textAlign: "right"}}>*/}
                {/*    <Button type="primary" icon={<PlusOutlined/>} onClick={this.AddTableRow.bind(this)}>新增</Button>*/}
                {/*</Col>*/}
                {/*<Col span={24}>*/}
                {/*    <Table columns={columns} dataSource={list} bordered/>*/}
                {/*</Col>*/}
                <Form initialValues={list} ref={this.formRef} layout='vertical' onFinish={this.editSaveForm.bind(this)}>
                    {DrawerContainer}
                </Form>
            </Row>
        )
    }
}

